.loader {
    width: 100%;
    height: 13px;
    text-align: center;
}

.dot {
    position: relative;
    width: 13px;
    height: 13px;
    margin: 0 6px;
    display: inline-block;
}

.dot:first-child:before {
    animation-delay: 0ms;
}

.dot:first-child:after {
    animation-delay: 0ms;
}

.dot:last-child:before {
    animation-delay: 200ms;
}

.dot:last-child:after {
    animation-delay: 200ms;
}

.dot:before {
    content: '';
    position: absolute;
    left: 0;
    width: 15px;
    height: 15px;
    background-color: blue;
    animation-name: dotHover;
    animation-duration: 900ms;
    animation-timing-function: cubic-bezier(0.82, 0, 0.26, 1);
    animation-iteration-count: infinite;
    animation-delay: 100ms;
    background: tomato;
    border-radius: 100%;
}

.dot:after {
    content: '';
    position: absolute;
    z-index: -1;
    background: black;
    box-shadow: 0px 0px 1px black;
    opacity: 0.2;
    width: 100%;
    height: 3px;
    left: 0;
    bottom: -2px;
    border-radius: 100%;
    animation-name: dotShadow;
    animation-duration: 900ms;
    animation-timing-function: cubic-bezier(0.82, 0, 0.26, 1);
    animation-iteration-count: infinite;
    animation-delay: 100ms;
}

@keyframes dotShadow {
    0% {
        transform: scaleX(1);
    }

    50% {
        opacity: 0;
        transform: scaleX(0.7);
    }

    100% {
        transform: scaleX(1);
    }
}

@keyframes dotHover {
    0% {
        top: 0px;
    }

    50% {
        top: -25px;
        transform: scale(1.1);
    }

    100% {
        top: 0;
    }
}
